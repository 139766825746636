import { Frame, Loading } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { ProductRule } from '~/containers/ProductRule/ProductRule'
import { Connection } from '~/containers/RuleForm/Connection'
import { ServiceContainer } from '~/services'
import { RuleItem } from '~/services/dynamodb/stores'

export const ProductRulePage = () => {
  const { state } = useContext(RootStateContext)
  const slugArray = window.location.pathname.split('/')
  const ruleId = slugArray[slugArray.length - 1]

  const [rule, setRule] = useState<RuleItem>()

  const fetchRule = useCallback(async () => {
    if (!state?.endpoint || !ruleId) return
    const rule = await ServiceContainer.client().rules.get(state.endpoint, ruleId.split('%23')[1])
    rule && setRule(rule)
  }, [ruleId, state.endpoint])

  useEffect(() => {
    fetchRule()
  }, [fetchRule])

  if (!state.initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Connection
      initialValues={{
        rule_name: rule?.rule_name,
        content_model_id: rule?.content_model_id,
        shopify_ref_field: rule?.shopify_ref_field,
        mappings: rule?.mappings,
        active: rule?.active,
      }}
      webhookId={rule?.webhook_id}
    >
      <ProductRule rule={rule} />
    </Connection>
  )
}

export default ProductRulePage

import { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb'

export default class DynamoDB {
  private ddbClient: DynamoDBClient
  private ddbDocClient: DynamoDBDocument
  constructor() {
    this.ddbClient = new DynamoDBClient({
      region: 'eu-central-1',
      credentials: {
        accessKeyId: process.env.DV_AWS_ACCESS_KEY_ID ?? '',
        secretAccessKey: process.env.DV_AWS_SECRET_ACCESS_KEY ?? '',
      },
    })
    this.ddbDocClient = DynamoDBDocument.from(this.ddbClient)
  }

  putItem = async (TableName: string, Item: any) => {
    try {
      await this.ddbDocClient.put({
        TableName,
        Item,
      })
      return true
    } catch (error) {
      throw new Error(error as string)
    }
  }

  getItem = async (
    TableName: string,
    Key: {
      [key: string]: any
    },
  ) => {
    try {
      const response = await this.ddbDocClient.get({
        TableName,
        Key,
      })
      if (response?.Item) return response.Item
      return undefined
    } catch (error) {
      throw new Error(error as string)
    }
  }

  deleteItem = async (
    TableName: string,
    Key: {
      [key: string]: any
    },
  ) => {
    try {
      await this.ddbDocClient.delete({
        TableName,
        Key,
      })
      return true
    } catch (error) {
      throw new Error(error as string)
    }
  }

  query = async (TableName: string, KeyConditionExpression: string, ExpressionAttributeValues?: any, FilterExpression?: string) => {
    try {
      const output = await this.ddbDocClient.query({ TableName, KeyConditionExpression, ExpressionAttributeValues, FilterExpression })
      if (output?.Items) return output.Items
      return undefined
    } catch (error) {
      throw new Error(error as string)
    }
  }

  scan = async (TableName: string, FilterExpression: string, ExpressionAttributeValues?: any) => {
    try {
      const output = await this.ddbDocClient.scan({ TableName, FilterExpression, ExpressionAttributeValues })
      if (output?.Items) return output.Items
      return undefined
    } catch (error) {
      throw new Error(error as string)
    }
  }

  updateItem = async (
    TableName: string,
    Key: {
      [key: string]: any
    },
    UpdateExpression: string,
    ExpressionAttributeValues?: any,
    ConditionExpression?: any
  ) => {
    try {
      return await this.ddbDocClient.update({
        TableName,
        Key,
        UpdateExpression,
        ExpressionAttributeValues,
        ConditionExpression,
      })
    } catch (error) {
      throw new Error(error as string)
    }
  }
}

import { Modal, useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import {
  Banner,
  Button,
  Card,
  DisplayText,
  Icon,
  Layout,
  List,
  OptionList,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from '@shopify/polaris'
import { CircleTickMajor } from '@shopify/polaris-icons'
import { useRouter } from 'next/router'
import React, { useContext, useRef, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { freeQuota, SubscriptionItem, subscriptionPlans } from '~/data/subscriptionPlans'
import { useFormatter } from '~/hooks/useFormatter'
import { ServiceContainer } from '~/services'
import { RecurringInterval } from '~/services/shopify/client'

export const SubscriptionPage = () => {
  const router = useRouter()
  const [edit, setEdit] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [selectedCycle, setSelectedCycle] = useState<RecurringInterval[]>([])

  const [enterDiscountCode, setEnterDiscountCode] = useState(false)
  const [discountCode, setDiscountCode] = useState('')

  const [errorState, setErrorState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false)

  const errorTimeout = useRef<NodeJS.Timeout>()

  const { formatCurrency } = useFormatter()

  const app = useAppBridge()

  const { state, dispatch } = useContext(RootStateContext)

  const handleUpdate = async () => {
    if (!state?.endpoint) return
    dispatch({ type: 'updating-subscription' })
    const plan = subscriptionPlans.find((plan) => plan.id === selectedItems[0]) as SubscriptionItem
    try {
      const result = await ServiceContainer.client().subscriptions.update({
        shop: state.endpoint,
        planName: plan.name,
        interval: selectedCycle[0],
        amount: selectedCycle[0] === RecurringInterval.ANNUAL ? plan?.yearly_amount ?? 0 : plan?.monthly_amount ?? 0,
        monthly_sync_quota: plan.monthly_sync_quota ?? freeQuota.monthly_sync_quota,
        max_rules: plan.max_rules ?? freeQuota.max_rules,
        discountPercentage: state.validDiscountCode ? 1 : 0,
      })
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, {
        url: result.confirmationUrl,
      })
      dispatch({ type: 'created-subscription' })
      setErrorState(false)
    } catch (error: any) {
      dispatch({ type: 'failed-update-subscription' })
      setErrorMessage(error.message)
      setErrorState(true)
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setErrorState(false)
      }, 3000)
    }
  }

  const checkDiscountCode = async () => {
    dispatch({ type: 'checking-discount-code' })
    try {
      await ServiceContainer.client().subscriptions.checkDiscountCode(discountCode)
      dispatch({ type: 'checked-discount-code', payload: { valid: true } })
      setErrorState(false)
    } catch (error) {
      dispatch({ type: 'checked-discount-code', payload: { valid: false } })
      setErrorMessage('Invalid discount code.')
      setErrorState(true)
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setErrorState(false)
      }, 3000)
    }
  }

  const handleCancelSubscription = async () => {
    if (!state?.endpoint) return
    dispatch({ type: 'cancelling-subscription' })
    try {
      await ServiceContainer.client().subscriptions.cancel(state.endpoint)
      dispatch({ type: 'cancelled-subscription' })
      setEdit(false)
    } catch (error) {
      dispatch({ type: 'failed-cancel-subscription' })
      setErrorMessage('Something went wrong while cancelling subscription. Please contact support.')
      setErrorState(true)
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setErrorState(false)
      }, 3000)
      setCancelSubscriptionModalOpen(false)
    }
  }

  const renderItem = (item: SubscriptionItem) => {
    const shortcutActions = item?.url
      ? [
          {
            content: 'Contact us',
            accessibilityLabel: `Contact us to know more about ${item.name} subscription plan.`,
            url: item.url,
          },
        ]
      : []
    return (
      <ResourceItem
        shortcutActions={shortcutActions}
        persistActions={!!item?.url}
        id={item.id}
        accessibilityLabel={`Choose subscription plan ${item.name}`}
        onClick={() => {}}
      >
        <h3>{item.name}</h3>
        <p>
          {item.monthly_sync_quota
            ? `${item.max_rules} synchronisation rules - ${item.monthly_sync_quota} synchronisation events/month`
            : `${item.description}`}
        </p>
      </ResourceItem>
    )
  }

  return (
    <Page
      title={edit ? 'Change plan' : 'Current plan'}
      pagination={{ hasPrevious: true, onPrevious: () => router.back() }}
    >
      <Layout>
        {errorState && (
          <Layout.Section>
            <Banner status="critical" title={errorMessage} onDismiss={() => setErrorState(false)} />
          </Layout.Section>
        )}
        <Layout.Section>
          {!edit && (
            <Card
              actions={[{ content: 'To home page', url: '/embedded', accessibilityLabel: 'Navigate to home page.' }]}
              primaryFooterAction={{ content: 'Change plan', onAction: () => setEdit(true) }}
            >
              <Card.Section title="Your current plan is">
                <TextContainer>
                  <DisplayText>{state?.config?.plan_name}</DisplayText>
                  <List>
                    <List.Item>{`${state?.config?.max_rules} synchronization rules`}</List.Item>
                    <List.Item>{`${state?.config?.monthly_sync_quota} synchronization events/month`}</List.Item>
                  </List>
                </TextContainer>
              </Card.Section>
            </Card>
          )}
          {edit && (
            <>
              <Card
                actions={[{ content: 'To home page', url: '/embedded', accessibilityLabel: 'Navigate to home page.' }]}
                secondaryFooterActions={[
                  {
                    content: 'Cancel subscription',
                    destructive: true,
                    onAction: () => setCancelSubscriptionModalOpen(true),
                    disabled: state?.config?.plan_name === 'Free',
                  },
                  {
                    content: 'Cancel',
                    onAction: () => {
                      setSelectedItems([])
                      setSelectedCycle([])
                      setEdit(false)
                      setEnterDiscountCode(false)
                      setDiscountCode('')
                      setErrorState(false)
                      dispatch({type: 'checked-discount-code', payload: {valid: false}})
                    },
                  },
                ]}
              >
                <Card.Header title="Plan selection" />
                <Card.Section title="Subscription plans">
                  <ResourceList
                    selectable
                    showHeader={false}
                    items={subscriptionPlans.filter(
                      (plan) => plan.name !== state?.config?.plan_name && plan.name !== 'Free',
                    )}
                    renderItem={renderItem}
                    selectedItems={selectedItems}
                    onSelectionChange={(ids: string[]) => {
                      setSelectedItems((prev) => ids.filter((id) => !prev.includes(id)))
                      setSelectedCycle([])
                      setEnterDiscountCode(false)
                      setDiscountCode('')
                      dispatch({type: 'checked-discount-code', payload: {valid: false}})
                      setErrorState(false)
                    }}
                  ></ResourceList>
                </Card.Section>
                {selectedItems.length > 0 && selectedItems[0] !== '4' && (
                  <>
                    <Card.Section title="Billing cycle">
                      <OptionList
                        onChange={(selected: string[]) =>
                          setSelectedCycle((selected as unknown) as RecurringInterval[])
                        }
                        selected={selectedCycle}
                        options={billingCycleOptions}
                      ></OptionList>
                    </Card.Section>
                    <Card.Section title="Discount code">
                      {!enterDiscountCode && (
                        <>
                          <TextStyle variation="strong">{'If you have a discount code '}</TextStyle>
                          <Button plain monochrome onClick={() => setEnterDiscountCode(true)}>
                            please click here
                          </Button>
                        </>
                      )}
                      {enterDiscountCode && (
                        <TextField
                          label="Discount code"
                          value={discountCode}
                          onChange={(value) => setDiscountCode(value)}
                          connectedLeft={
                            <Button
                              onClick={() => {
                                setDiscountCode('')
                                setEnterDiscountCode(false)
                                dispatch({type: 'checked-discount-code', payload: {valid: false}})
                                setErrorState(false)
                              }}
                            >
                              Cancel
                            </Button>
                          }
                          connectedRight={
                            !state.validDiscountCode ? (
                              <Button primary onClick={checkDiscountCode} loading={state.checkingDiscountCode}>
                                Check validity
                              </Button>
                            ) : (
                              <Icon source={CircleTickMajor} color="success" />
                            )
                          }
                        />
                      )}
                    </Card.Section>
                  </>
                )}
              </Card>
              <div>
                <Modal
                  open={cancelSubscriptionModalOpen}
                  onClose={() => setCancelSubscriptionModalOpen(false)}
                  title={'Cancel subscription?'}
                  primaryAction={{
                    content: 'Cancel subscription',
                    onAction: () => handleCancelSubscription(),
                    destructive: true,
                    loading: state.cancellingSubscription,
                  }}
                  secondaryActions={[{ content: 'Cancel', onAction: () => setCancelSubscriptionModalOpen(false) }]}
                  message={'You can purchase any other subscription plan anytime in the future.'}
                />
              </div>
            </>
          )}
          {selectedItems.length > 0 && selectedCycle.length > 0 && (
            <Card
              primaryFooterAction={{
                content: 'Update plan',
                onAction: handleUpdate,
                loading: state.updatingSubscription,
              }}
              title="Pricing details"
            >
              <Card.Section>
                <Stack>
                  <Stack.Item>
                    <TextStyle variation="subdued">
                      {`${subscriptionPlans.find((plan) => plan.id === selectedItems[0])?.name}: ${
                        billingCycleOptions.find((option) => option.value === selectedCycle[0])?.label
                      }`}
                    </TextStyle>
                  </Stack.Item>
                  <Stack.Item>
                    <span style={{ fontWeight: 600, textDecoration: state.validDiscountCode ? 'line-through' : '' }}>
                      {' '}
                      {selectedCycle[0] === 'EVERY_30_DAYS'
                        ? formatCurrency(
                            subscriptionPlans.find((plan) => plan.id === selectedItems[0])?.monthly_amount ?? 0,
                          )
                        : formatCurrency(
                            subscriptionPlans.find((plan) => plan.id === selectedItems[0])?.yearly_amount ?? 0,
                          )}
                    </span>
                    {state.validDiscountCode && <TextStyle variation="strong"> {formatCurrency(0)}</TextStyle>}
                  </Stack.Item>
                </Stack>
                <div style={{ height: 24 }} />
                <TextStyle variation="subdued">{`Charges are processed through Shopify's invoicing system and appear directly on the Shopify invoice.`}</TextStyle>
              </Card.Section>
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  )
}

const billingCycleOptions: { value: RecurringInterval; label: string }[] = [
  { value: RecurringInterval.EVERY_30_DAYS, label: 'Pay monthly' },
  { value: RecurringInterval.ANNUAL, label: 'Pay yearly' },
]

export default SubscriptionPage

import { Badge, Banner, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris'
import { RuleItem } from '~/services/dynamodb/stores'
import React, { useContext, useRef, useState } from 'react'
import { ServiceContainer } from '~/services'
import { Modal } from '@shopify/app-bridge-react'
import { RootStateContext } from '~/components/RootStateProvider'
import { captureException } from '@sentry/nextjs'

interface RulesListProps {
  type: 'products'
  rules: RuleItem[]
  refetchRules: () => Promise<void>
  loading: boolean
}

export const RulesList = (props: RulesListProps) => {
  const { type, rules } = props
  const { state } = useContext(RootStateContext)

  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [invalid, setInvalid] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)

  const errorTimeout = useRef<NodeJS.Timeout>()
  const successTimout = useRef<NodeJS.Timeout>()

  const toggleActive = async (ids: string[], active: boolean) => {
    if (!state?.endpoint) return
    await Promise.all(
      ids.map(async (id) => {
        const rule = props.rules.find((rule) => rule.data_variant === id)
        await ServiceContainer.ctfManagement(state.config.management_api_key).toggleStatus(
          rule?.webhook_id ?? '',
          active,
        )
        try {
          await ServiceContainer.client().rules.update({
            endpoint: state.endpoint as string,
            rule_name: rule?.rule_name as string,
            rule_id: id.split('#')[1],
            active,
          })
          setSuccessMessage('The rule has been successfully updated..')
          setSuccess(true)
          !!successTimout.current && clearTimeout(successTimout.current)
          successTimout.current = setTimeout(() => {
            setSuccess(false)
          }, 3000)
          return
        } catch (error: any) {
          setErrorMessage(error.message)
          setInvalid(true)
          !!errorTimeout.current && clearTimeout(errorTimeout.current)
          errorTimeout.current = setTimeout(() => {
            setInvalid(false)
          })
        }
      }),
    )
    props.refetchRules()
  }

  const syncNow = async (id: string) => {
    if (!state?.endpoint) return
    const rule = props.rules.find((rule) => rule.data_variant === id)
    if (!rule) return
    try {
      await ServiceContainer.client().rules.execute(rule)
      setSuccessMessage('The synchronisation has started.')
      setSuccess(true)
      !!successTimout.current && clearTimeout(successTimout.current)
      successTimout.current = setTimeout(() => {
        setSuccess(false)
      }, 3000)
      return
    } catch (error) {
      setErrorMessage('Something went wrong while starting the execution')
      setInvalid(true)
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setInvalid(false)
      }, 3000)
    }
  }

  const deleteRules = async (ids: string[]) => {
    if (!state?.endpoint) return
    setDeleting(true)
    await Promise.all(
      ids.map(async (id) => {
        const rule = props.rules.find((rule) => rule.data_variant === id)
        if (!rule) return
        try {
          await ServiceContainer.client().rules.delete(rule)
          await ServiceContainer.ctfManagement(state.config.management_api_key).deleteWebhook(rule?.webhook_id ?? '')
          setSuccessMessage('The rule has been deleted successfully.')
          setSuccess(true)
          !!successTimout.current && clearTimeout(successTimout.current)
          successTimout.current = setTimeout(() => {
            setSuccess(false)
          }, 3000)
          return
        } catch (error) {
          setErrorMessage('Something went wrong.')
          setInvalid(true)
          !!errorTimeout.current && clearTimeout(errorTimeout.current)
          errorTimeout.current = setTimeout(() => {
            setInvalid(false)
          }, 3000)
        }
      }),
    )
    setDeleteModalOpen(false)
    setDeleting(false)
    props.refetchRules()
  }

  const promotedBulkActions = [{ content: 'Delete Items', onAction: () => setDeleteModalOpen(true) }]

  const bulkActions = [
    {
      content: 'Activate',
      onAction: () => toggleActive(selectedItems, true),
    },
    {
      content: 'Deactivate',
      onAction: () => toggleActive(selectedItems, false),
    },
  ]

  const renderItem = (item: RuleItem) => {
    const { data_variant, rule_name, active } = item
    const shortcutActions = active
      ? [
          {
            content: 'Sync now',
            accessibilityLabel: `Execute the syncronisation of the ${type} rule ${rule_name}`,
            onAction: () => syncNow(data_variant),
          },
          {
            content: 'Deactivate',
            accessibilityLabel: `Deactivate ${type} rule ${rule_name}`,
            onAction: () => toggleActive([data_variant], false),
          },
        ]
      : [
          {
            content: 'Activate',
            accessibilityLabel: `activate ${type} rule ${rule_name}`,
            onAction: () => toggleActive([data_variant], true),
          },
        ]
    return (
      <ResourceItem
        id={data_variant}
        url={`/embedded/${type}-rule/${data_variant.replace('#', '%23')}`}
        accessibilityLabel={`View details for ${type} rule ${rule_name}`}
        shortcutActions={shortcutActions}
        media={<Badge status={active ? 'success' : 'warning'}>{active ? 'Active' : 'Inactive'}</Badge>}
      >
        <h3>
          <TextStyle variation={active ? 'strong' : 'subdued'}>{rule_name}</TextStyle>
        </h3>
      </ResourceItem>
    )
  }

  if ((!rules || !rules?.length || rules?.length === 0) && !props.loading)
    return (
      <p style={{ marginTop: 8 }}>
        <TextStyle variation="strong">There are no rules found yet.</TextStyle>
      </p>
    )
  return (
    <>
      {invalid && <Banner status="critical" title={errorMessage} onDismiss={() => setInvalid(false)} />}
      {success && <Banner status="success" title={successMessage} onDismiss={() => setSuccess(false)} />}
      <ResourceList
        resourceName={{ singular: `${type} rule`, plural: `${type} rules` }}
        items={rules}
        selectedItems={selectedItems}
        onSelectionChange={(ids: string[]) => {
          setSelectedItems(
            ids.map((id) => {
              if (id.startsWith('RULE')) return id
              return rules[Number(id)].data_variant
            }),
          )
        }}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        renderItem={renderItem}
        selectable
        loading={props.loading}
      />
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          title={'Delete synchronization rule?'}
          primaryAction={{
            content: 'Delete rule',
            onAction: () => deleteRules(selectedItems),
            destructive: true,
            loading: deleting,
          }}
          secondaryActions={[{ content: 'Cancel', onAction: () => setDeleteModalOpen(false) }]}
          message={'This cannot be undone.'}
        ></Modal>
      </div>
    </>
  )
}

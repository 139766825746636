import { SessionInterface } from '@shopify/shopify-api'
import { ServiceContainer } from '~/services'

export class DynamoDBSessions {
  private tableName: string
  constructor() {
    this.tableName = process.env.DYNAMODB_SESSIONS_TABLE_NAME ?? ''
  }

  public put = (session: SessionInterface) => {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    const { isActive, ...props } = session
    const expires = session?.expires ? session.expires.toISOString() : null
    return dynamoDbClient.putItem(this.tableName, { ...props, expires })
  }

  public get = (id: string) => {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.getItem(this.tableName, { id })
  }

  public delete = (id: string) => {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.deleteItem(this.tableName, { id })
  }
}

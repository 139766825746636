import { ContentfulManagement } from '~/services/contentful'
import DynamoDB from '~/services/dynamodb'
import { Client } from './client'

export const ServiceContainer = (function () {
  let ctfManagement: ContentfulManagement
  let dynamoDbClient: DynamoDB
  let client: Client

  function createCtfManagementInstance(apiKey: string) {
    return new ContentfulManagement(apiKey)
  }

  function createDynamoDBInstance() {
    return new DynamoDB()
  }

  function createClient() {
    return new Client()
  }

  return {
    ctfManagement: function (apiKey?: string) {
      if (!ctfManagement) {
        ctfManagement = createCtfManagementInstance(apiKey ?? '')
        return ctfManagement
      }
      return ctfManagement
    },
    dynamoDb: function () {
      if (!dynamoDbClient) {
        dynamoDbClient = createDynamoDBInstance()
        return dynamoDbClient
      }
      return dynamoDbClient
    },
    client: function () {
      if (!client) {
        client = createClient()
        return client
      }
      return client
    },
  }
})()

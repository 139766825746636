import { ConfigItem, RuleItem, RuleItemMapping } from '../dynamodb/stores'
import { RecurringInterval } from '../shopify/client'

export class Client {
  public rules = {
    getAll: async (endpoint: string): Promise<{ rules: RuleItem[]; sync_count: number | undefined } | null> => {
      const url = new URL(`${window.location.origin}/api/stores/rules`)
      url.searchParams.append('endpoint', endpoint)
      const response = await fetch(url.toString())
      if (response.status === 200) {
        return await response.json()
      }
      return { rules: [], sync_count: 0 }
    },
    get: async (endpoint: string, id: string): Promise<RuleItem | null> => {
      const url = new URL(`${window.location.origin}/api/stores/rules`)
      url.searchParams.append('endpoint', endpoint as string)
      url.searchParams.append('id', id)
      const response = await fetch(url.toString())
      if (response.status === 200) {
        return await response.json()
      }
      return null
    },
    create: async (input: CreateRuleInput) => {
      const url = new URL(`${window.location.origin}/api/stores/rules`)
      const response = await fetch(url.toString(), { method: 'POST', body: JSON.stringify(input) })
      if (response.status !== 201) {
        throw new Error('Something went wrong creating new rule.')
      }
      return
    },
    update: async (input: UpdateRuleInput) => {
      const url = new URL(`${window.location.origin}/api/stores/rules`)
      const response = await fetch(url.toString(), {
        method: 'PUT',
        body: JSON.stringify(input),
      })
      if (response.status !== 200) {
        throw new Error('Something went wrong while updating a rule')
      }
      return
    },
    execute: async (rule: RuleItem) => {
      const url = new URL(`${window.location.origin}/api/stores/rules/execute`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify({ ...rule }),
      })
      if (response.status !== 200) {
        throw new Error('Something went wrong initializing the rule execution.')
      }
      return
    },
    delete: async (rule: RuleItem) => {
      const url = new URL(`${window.location.origin}/api/stores/rules/delete`)
      const response = await fetch(url.toString(), { method: 'POST', body: JSON.stringify(rule) })
      if (response.status !== 200) {
        throw new Error('Something went wrong while deleting the rule')
      }
      return
    },
  }

  public config = {
    get: async (endpoint: string): Promise<ConfigItem | null> => {
      const url = new URL(`${window.location.origin}/api/stores/config`)
      url.searchParams.append('endpoint', endpoint)
      const response = await fetch(url.toString())
      if (response.status === 200) {
        return await response.json()
      }
      return null
    },
    create: async (config: Omit<ConfigItem, 'data_variant'>): Promise<void> => {
      const url = new URL(`${window.location.origin}/api/stores/config`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify(config),
      })
      if (response.status !== 200) {
        throw new Error('Something went wrong while creating config')
      }
      return
    },
  }

  public ctf_entries = {
    ofProduct: async (endpoint: string, productId: string): Promise<{ ctfEntryIds: string[] }> => {
      const url = new URL(`${window.location.origin}/api/ctf-entries`)
      url.searchParams.append('shop', endpoint)
      url.searchParams.append('id', productId)
      const response = await fetch(url.toString())
      if (response.status === 200) {
        return await response.json()
      }
      return { ctfEntryIds: [] }
    },
  }

  public subscriptions = {
    update: async (input: UpdateSubscriptionInput): Promise<{ confirmationUrl: string }> => {
      const url = new URL(`${window.location.origin}/api/subscription`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify(input),
      })
      if (response.status === 200) {
        return await response.json()
      }
      throw new Error('Something went wrong while updating subscription')
    },

    checkDiscountCode: async (code: string) => {
      const url = new URL(`${window.location.origin}/api/discount-code/check`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          code,
        }),
      })
      if (response.status !== 200) {
        throw new Error('Invalid discount code')
      }
      return
    },

    cancel: async (endpoint: string) => {
      const url = new URL(`${window.location.origin}/api/subscription/delete`)
      const response = await fetch(url.toString(), {
        method: 'POST',
        body: JSON.stringify({ shop: endpoint }),
      })
      if (response.status !== 200) {
        throw new Error('Something went wrong while cancelling subscription')
      }
      return
    },
  }
}

export interface CreateRuleInput {
  endpoint: string
  mappings: RuleItemMapping[]
  active: boolean
  webhook_url: string
  webhook_id: string
  rule_type: string
  rule_name: string
  shopify_ref_field: string
  content_model_id: string
}

export interface UpdateRuleInput {
  endpoint: string
  rule_name: string
  rule_id: string
  active?: boolean
  shopify_ref_field?: string
  content_model_id?: string
  mappings?: RuleItemMapping[]
}

interface UpdateSubscriptionInput {
  shop: string
  planName: string
  interval: RecurringInterval
  amount: number
  monthly_sync_quota: number
  max_rules: number
  discountPercentage: number
}

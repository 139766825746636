import { Page, Layout, Banner, Card } from '@shopify/polaris'
import { useRouter } from 'next/router'
import React, { useContext, useRef, useState } from 'react'
import { RootStateContext } from '~/components/RootStateProvider'
import { ServiceContainer } from '~/services'
import { RuleItem } from '~/services/dynamodb/stores'
import { ConnectionContext } from '../RuleForm/Connection'
import { ConnectionDetailsForm } from '../RuleForm/ConnectionDetails'
import { ConnectionRulesForm } from '../RuleForm/ConnectionRules'

export type FormRule = Omit<
  RuleItem,
  'active' | 'rule_type' | 'endpoint' | 'data_variant' | 'webhook_url' | 'webhook_id'
>

export const NewProductRule = () => {
  const { values, titleTemplate, descriptionTemplate } = useContext(ConnectionContext)

  const router = useRouter()
  const { state } = useContext(RootStateContext)

  const checkValidForm = (rule?: FormRule) => {
    if (rule?.mappings.length === 0 || !rule?.content_model_id || !rule?.rule_name || !rule?.shopify_ref_field) {
      throw new Error('Not valid')
    }
    return
  }

  const successTimout = useRef<NodeJS.Timeout>()
  const errorTimeout = useRef<NodeJS.Timeout>()

  const [success, setSuccess] = useState<boolean>(false)
  const [invalidForm, setInvalidForm] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const saveRule = async () => {
    if (!state?.endpoint) return
    setSaving(true)
    try {
      checkValidForm(values)
      const webhooks = await ServiceContainer.ctfManagement(state.config.management_api_key).createWebhook(
        state.endpoint,
        values?.rule_name ?? '',
        values?.content_model_id ?? '',
      )
      const mappings =
        values?.mappings.map((mapping) => {
          switch (mapping.key) {
            case 'Title':
              return { ...mapping, template: titleTemplate }
            case 'Description':
              return { ...mapping, template: descriptionTemplate }
            default:
              return mapping
          }
        }) ?? []
      await ServiceContainer.client().rules.create({
        endpoint: state.endpoint,
        content_model_id: values?.content_model_id as string,
        shopify_ref_field: values?.shopify_ref_field as string,
        rule_name: values?.rule_name as string,
        mappings,
        active: true,
        webhook_url: webhooks.url,
        webhook_id: webhooks.sys.id,
        rule_type: 'Product',
      })
      setSuccess(true)
      !!successTimout.current && clearTimeout(successTimout.current)
      successTimout.current = setTimeout(() => {
        setSuccess(false)
        router.back()
      }, 3000)
      setSaving(false)
      return
    } catch (error) {
      console.log(error)

      setInvalidForm(true)
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        setInvalidForm(false)
      }, 3000)
      setSaving(false)
      return
    }
  }

  const saveDisabled =
    !values?.shopify_ref_field || !values?.content_model_id || !values.rule_name || values.mappings.length === 0

  return (
    <Page
      title={'New synchronization rule'}
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        {invalidForm && (
          <Layout.Section>
            <Banner
              status="critical"
              title="Please enter rule name, content model, shopify reference field and at least one rule definition."
              onDismiss={() => setInvalidForm(false)}
            />
          </Layout.Section>
        )}
        {success && (
          <Layout.Section>
            <Banner
              status="success"
              title="The rule has been successfully created."
              onDismiss={() => {
                setSuccess(false)
                router.back()
              }}
            />
          </Layout.Section>
        )}
        <Layout.Section>
          <Card
            primaryFooterAction={{
              content: 'Save',
              onAction: saveRule,
              loading: saving,
              disabled: saveDisabled,
            }}
            actions={[{ content: 'To home page', url: '/embedded', accessibilityLabel: 'Navigate to home page.' }]}
          >
            <Card.Section title="Connection details">
              <ConnectionDetailsForm />
            </Card.Section>
            <Card.Section title="Connection rules">
              <ConnectionRulesForm />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

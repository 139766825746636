export interface SubscriptionItem {
  id: string
  name: string
  monthly_sync_quota?: number
  max_rules?: number
  monthly_amount?: number
  yearly_amount?: number
  url?: string
  description?: string
}

export const freeQuota = {
  max_rules: 2,
  monthly_sync_quota: 1000,
  name: 'Free'
}

export const subscriptionPlans: SubscriptionItem[] = [
  {
    id: '1',
    ...freeQuota,
    monthly_amount: 0,
    yearly_amount: 0,
  },
  {
    id: '2',
    name: 'Starter',
    max_rules: freeQuota.max_rules,
    monthly_sync_quota: 5000,
    monthly_amount: 10,
    yearly_amount: 100,
  },
  {
    id: '3',
    name: 'Pro',
    max_rules: 5,
    monthly_sync_quota: 50000,
    monthly_amount: 50,
    yearly_amount: 500,
  },
  {
    id: '4',
    name: 'Enterprise',
    description: 'More synchronization rules and events',
    url: 'example.com',
  },
]

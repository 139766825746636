import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import Shopify from '@shopify/shopify-api'
import { isArray, isEmpty, isObject, isString, uniq } from 'lodash'
import Mustache from 'mustache'
import showdown from 'showdown'
import { DynamoDBSessions } from '../dynamodb/sessions'
import { ConfigItem, DynamoDBStores, RuleItem } from '../dynamodb/stores'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulManagement } from '../contentful'

let ctfManagement: ContentfulManagement
let defaultLocaleCode: string = 'en-US'

export enum RecurringInterval {
  'ANNUAL' = 'ANNUAL',
  'EVERY_30_DAYS' = 'EVERY_30_DAYS',
}
interface AppSubscriptionLineItemInput {
  plan: {
    appRecurringPricingDetails: {
      price: {
        amount: number
        currencyCode: 'USD'
      }
      interval?: RecurringInterval
      discount?: {
        value: {
          percentage?: number
          amount?: number
        }
      }
    }
  }
}

export const shopifyClient = async (endpoint: string) => {
  const privateMetafieldsNamespace = process.env.SHOPIFY_PRIVATE_METAFIELDS_NAMESPACE as string
  const host = (process.env.HOST as string) ?? ''
  const dynamoDBSessions = new DynamoDBSessions()
  const session = await dynamoDBSessions.get(`offline_${endpoint as string}`)
  const gqlClient = new Shopify.Clients.Graphql(endpoint as string, session?.accessToken)
  const storesTable = new DynamoDBStores()
  const config = (await storesTable.get(endpoint, 'CONFIG')) as ConfigItem
  ctfManagement = new ContentfulManagement(config.management_api_key)
  await ctfManagement.getSpace(config.space_id)
  await ctfManagement.getEnvironment(config.environment_id)
  defaultLocaleCode = await ctfManagement.getDefaultLocaleCode()

  const getProductConnectedCtfEntries = async (productId: string) => {
    const promiseReturn = await gqlClient.query({
      data: {
        query: GetProductPrivateMetafield,
        variables: { id: productId, namespace: privateMetafieldsNamespace ?? '', key: 'ctfEntryIds' },
      },
    })
    const jsonString = (promiseReturn.body as any)?.data?.product?.privateMetafield?.value
    const value = jsonString ? (JSON.parse(jsonString) as { entries: string[] }) : { entries: [] }
    return value.entries
  }

  const updateProduct = async (payload: any, rule: RuleItem, entryId: string) => {
    const { input, productId } = await generateProductUpdateInput(payload, rule, entryId)
    let entries = await getProductConnectedCtfEntries(productId)

    if (!entries.includes(entryId)) {
      entries.push(entryId)
    }

    const updateProductInput = {
      ...input,
      privateMetafields: [
        {
          key: 'ctfEntryIds',
          namespace: privateMetafieldsNamespace,
          valueInput: {
            value: JSON.stringify({ entries }),
            valueType: 'JSON_STRING',
          },
        },
      ],
    }

    return await gqlClient.query({
      data: {
        query: UpdateProductMutation,
        variables: { input: updateProductInput, namespace: privateMetafieldsNamespace ?? '' },
      },
    })
  }

  const createSubscription = async (
    shop: string,
    planName: string,
    interval: RecurringInterval,
    amount: number,
    discountPercentage?: number,
  ) => {
    const lineItems: AppSubscriptionLineItemInput[] = [
      {
        plan: {
          appRecurringPricingDetails: {
            interval,
            price: { amount, currencyCode: 'USD' },
            discount: discountPercentage
              ? {
                  value: {
                    percentage: discountPercentage,
                  },
                }
              : undefined,
          },
        },
      },
    ]

    return await gqlClient.query({
      data: {
        query: CreateSubscriptionMutation,
        variables: {
          lineItems,
          name: planName,
          returnUrl: `${host}/embedded?shop=${shop}&host=${Buffer.from(`${shop}/admin`).toString('base64')}`,
        },
      },
    })
  }

  const cancelSubscription = async (id: string) => {
    return await gqlClient.query({
      data: {
        query: CancelSubscriptionMutation,
        variables: {
          id,
        },
      },
    })
  }

  return {
    updateProduct,
    updateProducts: async (rule: RuleItem) => {
      const entries = await ctfManagement.getEntries(rule.content_model_id)
      entries.forEach(async (entry) => {
        try {
          await updateProduct(entry.fields, rule, entry.sys.id)
        } catch (error) {
          throw new Error((error as any).message)
        }
      })
    },
    deleteRuleConnectedCtfEntriesPrivateMetafield: async (rule: RuleItem) => {
      const entries = await ctfManagement.getEntries(rule.content_model_id)
      await Promise.all(
        entries.map(async (entry) => {
          try {
            const productIdArray = Buffer.from(entry.fields[rule.shopify_ref_field][defaultLocaleCode], 'base64')
              .toString()
              .split('/')
            const id = productIdArray[productIdArray.length - 1]
            await gqlClient.query({
              data: {
                query: DeleteProductPrivateMetafield,
                variables: {
                  input: {
                    owner: `gid://shopify/Product/${id}`,
                    namespace: privateMetafieldsNamespace ?? '',
                    key: 'ctfEntryIds',
                  },
                },
              },
            })
          } catch (error) {
            throw new Error((error as any).message)
          }
        }),
      )
    },
    getProductConnectedCtfEntries,
    createSubscription,
    cancelSubscription,
  }
}

export type UpdateProductInput = {
  id: string
  bodyHtml?: string
  title?: string
  images?: { src: string }[]
  privateMetafields?: {
    key: string
    namespace: string
    valueInput?: {
      value: string
      valueType: string
    }
  }[]
}

const UpdateProductMutation = `
mutation productUpdate($input: ProductInput!, $namespace: String) {
  productUpdate(input: $input) {
    product {
      id
      title
      descriptionHtml
      privateMetafields(first: 10, namespace: $namespace) {
        edges {
          node {
            id
            key
            namespace
            value
            valueType
          }
        }
      }
      images(first: 10) {
        edges {
          node {
            id
            src
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
`

const GetProductPrivateMetafield = `
query getProduct($id: ID!, $namespace: String!, $key: String!) {
  product(id: $id) {
    privateMetafield(namespace: $namespace, key: $key) {
      value
    }
  }
}
`

const DeleteProductPrivateMetafield = `
mutation privateMetafieldDelete($input: PrivateMetafieldDeleteInput!) {
  privateMetafieldDelete(input: $input) {
    deletedPrivateMetafieldId
    userErrors {
      field
      message
    }
  }
}
`

const CreateSubscriptionMutation = `
mutation appSubscriptionCreate($lineItems: [AppSubscriptionLineItemInput!]!, $name: String!, $returnUrl: URL!) {
  appSubscriptionCreate(lineItems: $lineItems, name: $name, returnUrl: $returnUrl) {
    appSubscription {
      createdAt
      currentPeriodEnd
      id
      status
      trialDays
      test
    }
    confirmationUrl
    userErrors {
      field
      message
    }
  }
}
`

const CancelSubscriptionMutation = `
mutation appSubscriptionCancel($id: ID!) {
  appSubscriptionCancel(id: $id) {
    appSubscription {
      id
      status
    }
    userErrors {
      field
      message
    }
  }
}
`

class UpdateProductInputFields {
  title?: string
  description?: string
  images?: { src: string }[]
}

export const generateProductUpdateInput = async (
  payload: any,
  rule: RuleItem,
  entryId: string,
): Promise<{ input: UpdateProductInput; productId: string }> => {
  const ctfEntry: any = Object.entries<{ [key: string]: string }>(payload).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value[defaultLocaleCode] }
  }, {})
  const updateProductInput = (
    await Promise.all(
      rule.mappings.map(async (mapping) => {
        let output: string | undefined | { src: string }[]
        if (mapping.key === 'Title') {
          output = await parseTitleField(ctfEntry, mapping.template)
        }
        if (mapping.key === 'Description') {
          output = await parseDescriptionField(ctfEntry, mapping.template)
        }
        if (mapping.key === 'Images') {
          output = await parseImageField(ctfEntry, mapping.template, defaultLocaleCode)
        }
        return { key: mapping.key.toLocaleLowerCase(), value: output }
      }),
    )
  ).reduce((prev, current) => {
    return { ...prev, [current.key]: current.value }
  }, {}) as UpdateProductInputFields
  const productIdArray = Buffer.from(payload[rule.shopify_ref_field][defaultLocaleCode], 'base64').toString().split('/')
  const id = productIdArray[productIdArray.length - 1]
  return {
    input: {
      id: `gid://shopify/Product/${id}`,
      bodyHtml: updateProductInput?.description,
      title: updateProductInput?.title,
      images: updateProductInput?.images,
    },
    productId: `gid://shopify/Product/${id}`,
  }
}

const splitTemplateInArray = (template: string) => {
  const regExp = /[^{{{\}]+(?=}}})/g
  return template.match(regExp)
}

const parseTitleField = async (ctfEntry: any, template: string) => {
  const templateKeys = splitTemplateInArray(template) ?? []
  if (templateKeys.some((key) => !isEmpty(ctfEntry[key]) && !isString(ctfEntry[key]))) {
    throw new Error(
      'Connected title field is not compatible. Make sure to use only text fields (no rich text) for title synchronisation.',
    )
  }
  return Mustache.render(template, ctfEntry)
}

const parseDescriptionField = async (ctfEntry: any, template: string) => {
  const templateKeys = splitTemplateInArray(template) ?? []
  const converter = new showdown.Converter()
  await Promise.all(
    templateKeys.map(async (key) => {
      if (isObject(ctfEntry[key])) {
        try {
          const htmlString = await documentToHtmlString(ctfEntry[key], {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: () => '',
              [BLOCKS.EMBEDDED_ENTRY]: () => '',
              [INLINES.ASSET_HYPERLINK]: () => '',
              [INLINES.EMBEDDED_ENTRY]: () => '',
              [INLINES.ENTRY_HYPERLINK]: () => '',
            },
          })
          ctfEntry[key] = htmlString
        } catch (error) {
          throw new Error(
            'Connected description field is not compatible. Make sure you connect short, long or rich text fields.',
          )
        }
      }
      if (isString(ctfEntry[key])) {
        try {
          const htmlString = converter.makeHtml(ctfEntry[key])
          ctfEntry[key] = htmlString
        } catch (error) {
          throw new Error(
            'Connected description field is not compatible. Make sure you connect short, long or rich text fields.',
          )
        }
      } else if (!isEmpty(ctfEntry[key])) {
        throw new Error(
          'Connected description field is not compatible. Make sure you connect short, long or rich text fields.',
        )
      }
    }),
  )
  return Mustache.render(template, ctfEntry)
}

const parseImageField = async (ctfEntry: any, template: string, localeCode: string) => {
  const templateKeys = splitTemplateInArray(template) ?? []
  const srcArrayOfArrays = await Promise.all(
    templateKeys?.map(async (key) => {
      if (isEmpty(ctfEntry[key])) {
        return []
      }
      if (!isArray(ctfEntry[key])) {
        if (ctfEntry[key]?.sys.type !== 'Link' || ctfEntry[key]?.sys.linkType !== 'Asset') {
          throw new Error('Connected media field is not compatible')
        }
        try {
          const asset = await ctfManagement.retrieveAsset(ctfEntry[key]?.sys.id)
          const src = `https:${asset.fields.file[localeCode].url}` ?? ''
          return [{ src }]
        } catch (error) {
          throw new Error('Something went wrong while retrieving an asset')
        }
      }

      return await Promise.all(
        ctfEntry[key].map(async (imageObject: any) => {
          if (imageObject?.sys.type !== 'Link' || imageObject?.sys.linkType !== 'Asset') {
            throw new Error('Connected media field is not compatible')
          }
          try {
            const asset = await ctfManagement.retrieveAsset(imageObject?.sys.id)
            const src = `https:${asset.fields.file[localeCode].url}` ?? ''
            return { src }
          } catch (error) {
            throw new Error('Something went wrong while retrieving an asset')
          }
        }),
      )
    }),
  )
  return srcArrayOfArrays.flat()
}

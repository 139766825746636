import { ServiceContainer } from '~/services'

export type RuleDataVariant = `RULE#${string}`

interface BaseStoreItem {
  endpoint: string
  data_variant: 'CONFIG' | RuleDataVariant | 'SUBSCRIPTION'
}

export interface ConfigItem extends BaseStoreItem {
  space_id: string
  management_api_key: string
  environment_id: string
  last_synced_month?: number
  sync_count?: number
  monthly_sync_quota?: number
  max_rules?: number
  plan_name?: string
}

export interface RuleItemMapping {
  key: 'Title' | 'Description' | 'Images'
  template: string
  type: 'string' | 'media'
}

export interface RuleItem extends BaseStoreItem {
  rule_name: string
  shopify_ref_field: string
  webhook_url: string
  webhook_id: string
  active: boolean
  content_model_id: string
  rule_type: 'Product'
  mappings: RuleItemMapping[]
}

export interface SubscriptionItem extends BaseStoreItem {
  admin_graphql_api_id: string
  name: string
  status: 'ACTIVE' | 'CANCELLED' | 'DECLINED' | 'EXPIRED' | 'FROZEN' | 'PENDING' | 'ACCEPTED'
  admin_graphql_shop_id: string
  created_at: string
  updated_at: string
}

export type StoresItem = ConfigItem | RuleItem | SubscriptionItem

export interface UpdateConfigParams {
  monthly_sync_quota: number
  max_rules: number
  plan_name: string
}
export class DynamoDBStores {
  private tableName: string
  constructor() {
    this.tableName = process.env.DYNAMODB_STORES_TABLE_NAME ?? ''
  }

  put(item: StoresItem) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.putItem(this.tableName, item)
  }

  get(endpoint: string, data_variant: BaseStoreItem['data_variant']) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.getItem(this.tableName, { endpoint, data_variant })
  }

  delete(endpoint: string, data_variant: BaseStoreItem['data_variant']) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.deleteItem(this.tableName, { endpoint, data_variant })
  }

  queryAllRules(endpoint: string) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.query(this.tableName, `endpoint = :endpoint AND begins_with(data_variant, :RULE)`, {
      ':endpoint': endpoint,
      ':RULE': 'RULE',
    })
  }

  queryRule(endpoint: string, ruleName: string) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    return dynamoDbClient.query(
      this.tableName,
      `endpoint = :endpoint AND begins_with(data_variant, :RULE)`,
      {
        ':endpoint': endpoint,
        ':name': ruleName,
        ':RULE': 'RULE',
      },
      `rule_name = :name`,
    )
  }

  updateConfig(endpoint: string, params: UpdateConfigParams) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    const updateStatement = `SET monthly_sync_quota=:monthly_sync_quota, max_rules=:max_rules, plan_name=:plan_name`
    const expressionAttributeValues = {
      [':monthly_sync_quota']: params.monthly_sync_quota,
      [':max_rules']: params.max_rules,
      [':plan_name']: params.plan_name,
    }
    dynamoDbClient.updateItem(
      this.tableName,
      { endpoint, data_variant: 'CONFIG' },
      updateStatement,
      expressionAttributeValues,
    )
  }

  updateRule(
    endpoint: string,
    data_variant: BaseStoreItem['data_variant'],
    params: {
      rule_name: string
      content_model_id?: string
      shopify_ref_field?: string
      mappings?: RuleItemMapping[]
      active?: boolean
    },
  ) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    const updateStatement = `SET rule_name=:rule_name${
      params.content_model_id ? ', content_model_id=:content_model_id' : ''
    }${params.shopify_ref_field ? ', shopify_ref_field=:shopify_ref_field' : ''}${
      params.mappings ? ', mappings=:mappings' : ''
    }${params.active !== undefined ? ', active=:active' : ''}`

    const expressionAttributeValues = {}

    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined) return
      Object.assign(expressionAttributeValues, { [`:${key}`]: value })
    })
    dynamoDbClient.updateItem(this.tableName, { endpoint, data_variant }, updateStatement, expressionAttributeValues)
  }

  async incrementSyncCount(endpoint: string) {
    const dynamoDbClient = ServiceContainer.dynamoDb()
    const configItem = (await this.get(endpoint, 'CONFIG')) as ConfigItem

    const month = new Date().getMonth()

    const updateStatement =
      configItem?.last_synced_month === month ? `ADD sync_count :q` : `SET last_synced_month=:month, sync_count=:q`

    const expressionAttributeValues =
      configItem?.last_synced_month === month
        ? { [':q']: 1, [':limit']: configItem.monthly_sync_quota }
        : { [':q']: 1, [':month']: month }

    const conditionExpression = configItem?.last_synced_month === month ? 'sync_count < :limit' : undefined

    return dynamoDbClient.updateItem(
      this.tableName,
      { endpoint, data_variant: 'CONFIG' },
      updateStatement,
      expressionAttributeValues,
      conditionExpression,
    )
  }
}

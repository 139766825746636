import React, { useEffect, useState } from 'react'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'
import { BrowserRouter } from 'react-router-dom'
import { PolarisProvider } from '../PolarisProvider'
import { RootStateProvider } from '../RootStateProvider'
import { RoutePropagator } from '../RoutePropagator'
import { ApolloProvider } from '../ApolloProvider'

export const EmbeddedApp: React.FC = (props) => {
  const API_KEY = process.env.NEXT_PUBLIC_SHOPIFY_APP_API_KEY as string
  const [host, setHost] = useState<string>()

  useEffect(() => {
    const url = new URL(window.location.href)
    const host = url.searchParams.get('host')

    if (host) {
      setHost(host)
    } else {
      window.location.pathname = `/api/auth/shopify/login`
    }
  }, [])

  return (
    <>
      {host && (
        <BrowserRouter>
          <PolarisProvider>
            <AppBridgeProvider config={{ apiKey: API_KEY, host, forceRedirect: true }}>
              <RootStateProvider>
                <RoutePropagator />
                <ApolloProvider>{props.children}</ApolloProvider>
              </RootStateProvider>
            </AppBridgeProvider>
          </PolarisProvider>
        </BrowserRouter>
      )}
    </>
  )
}

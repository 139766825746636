import { ResourcePicker, useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { SelectPayload } from '@shopify/app-bridge/actions/ResourcePicker'
import {
  Layout,
  Banner,
  Button,
  EmptyState,
  Card,
  DescriptionList,
  Stack,
  ProgressBar,
  TextStyle,
  Link,
  FooterHelp,
} from '@shopify/polaris'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { EntityState, RootStateContext } from '~/components/RootStateProvider'
import { freeQuota } from '~/data/subscriptionPlans'
import { ServiceContainer } from '~/services'
import { ConfigItem } from '~/services/dynamodb/stores'
import { RulesList } from '../RulesList'
import { ConfigContext } from './ConfigForm'
import { ConfigForm } from './ConfigForm/form'

export const ContentfulConfig = () => {
  const imageUrl = 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'
  const { state, dispatch } = useContext(RootStateContext)
  const { values, reset } = useContext(ConfigContext)
  const { endpoint, config, emptyState, sync_count, rules } = state
  const app = useAppBridge()

  const [errorTitle, setErrorTitle] = useState<string>('')
  const [openResourcePicker, setOpenResourcePicker] = useState<boolean>(false)

  const errorTimeout = useRef<NodeJS.Timeout>()
  const successTimout = useRef<NodeJS.Timeout>()

  const checkCtfConfig = async (config: Omit<ConfigItem, 'data_variant' | 'endpoint'> | undefined) => {
    try {
      const ctfManagement = ServiceContainer.ctfManagement(config?.management_api_key)
      await ctfManagement.initialize(config?.space_id ?? '', config?.environment_id ?? 'master')
    } catch (error) {
      setErrorTitle('Please enter valid Space Id, Api key and Environment.')
      throw error
    }
    return
  }

  const handleSubmit = async () => {
    if (!endpoint) return
    dispatch({ type: 'upserting-config' })
    try {
      await checkCtfConfig(values)
      await ServiceContainer.client().config.create({
        ...values,
        endpoint,
        environment_id: !!values?.environment_id ? values.environment_id : 'master',
        monthly_sync_quota: config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota,
      } as Omit<ConfigItem, 'data_variant'>)
      dispatch({
        type: 'upserted-config',
        payload: {
          config: {
            ...state.config,
            data_variant: 'CONFIG',
            endpoint,
            environment_id: values?.environment_id ?? 'master',
            space_id: values?.space_id ?? '',
            management_api_key: values?.management_api_key ?? '',
          },
        },
      })
      await ServiceContainer.ctfManagement(values?.management_api_key).initialize(
        values?.space_id ?? '',
        values?.environment_id ?? 'master',
      )
      !!successTimout.current && clearTimeout(successTimout.current)
      successTimout.current = setTimeout(() => {
        dispatch({ type: 'updated-config-state', payload: { state: EntityState.IDLE } })
      }, 3000)
      return
    } catch (error) {
      dispatch({ type: 'updated-config-state', payload: { state: EntityState.INVALID } })
      !!errorTimeout.current && clearTimeout(errorTimeout.current)
      errorTimeout.current = setTimeout(() => {
        dispatch({ type: 'updated-config-state', payload: { state: EntityState.IDLE } })
      }, 3000)
      dispatch({ type: 'upsert-failed-config' })
      reset()
    }
  }

  const fetchRules = useCallback(async () => {
    if (!endpoint) return
    dispatch({ type: 'loading-rules' })
    const result = await ServiceContainer.client().rules.getAll(endpoint)
    dispatch({ type: 'fetched-rules', payload: { rules: result?.rules ?? [], sync_count: result?.sync_count ?? 0 } })
  }, [dispatch, endpoint])

  const handleSelection = (resources: SelectPayload) => {
    if (!endpoint) return
    const idArray = resources.selection[0].id.split('/')
    const id = idArray[idArray.length - 1]
    setOpenResourcePicker(false)
    const redirect = Redirect.create(app)
    redirect.dispatch(Redirect.Action.APP, `/embedded/ctf-entries?id=${id}&shop=${endpoint}`)
  }

  const refetchConfig = async () => {
    if (!endpoint) return
    dispatch({ type: 'refreshing-config' })
    const config = await ServiceContainer.client().config.get(endpoint)
    dispatch({ type: 'fetched-config', payload: { config } })
  }

  return (
    <>
      {state.config_state === EntityState.INVALID && (
        <Layout.Section>
          <Banner
            status="critical"
            title={errorTitle}
            onDismiss={() => dispatch({ type: 'updated-config-state', payload: { state: EntityState.IDLE } })}
          />
        </Layout.Section>
      )}
      {state.config_state === EntityState.SUCCESS && (
        <Layout.Section>
          <Banner
            status="success"
            title="The configuration has been successfully saved."
            onDismiss={() => dispatch({ type: 'updated-config-state', payload: { state: EntityState.IDLE } })}
          />
        </Layout.Section>
      )}
      {!emptyState && !state.loadingConfig && (
        <Layout.Section>
          <Banner status="info">
            <span>
              <TextStyle variation="strong">
                {`You are currently on a ${config?.plan_name ?? freeQuota.name} plan limited to ${
                  config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota
                } synchronisation events and ${config?.max_rules ?? freeQuota.max_rules} synchronisation rules. `}
              </TextStyle>
              <Link url="/embedded/subscription">Update now</Link>
              <br />
              <TextStyle variation="subdued">{`You have updated your subscription plan, but changes are not applied yet? `}</TextStyle>
              <Button plain monochrome onClick={refetchConfig}>
                Refresh now
              </Button>
            </span>
          </Banner>
        </Layout.Section>
      )}
      {!emptyState &&
        (sync_count > (config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota) - 100 ||
          rules.length === config?.max_rules) && (
          <Layout.Section>
            <Banner status="warning">
              <span>
                <TextStyle variation="strong">
                  {`You have used ${rules.length} out of ${
                    config?.max_rules ?? freeQuota.max_rules
                  } and ${sync_count} out of ${
                    config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota
                  } included in the ${config?.plan_name ?? freeQuota.name} plan. `}
                </TextStyle>
                <Link url="/embedded/subscription">Upgrade now</Link>
              </span>
            </Banner>
          </Layout.Section>
        )}
      {emptyState && (
        <Layout.Section>
          <EmptyState heading="Configure Contentful Connection" image={imageUrl}>
            <p style={{ paddingBottom: '1rem' }}>
              Configure Contentful Space Id to synchronize marketing purpose product informations between headless CMS
              and Shopify for higher flexibility.
            </p>
            <ConfigForm />
            <div style={{ paddingTop: '2rem' }}></div>
            <Button primary loading={state.savingConfig} onClick={handleSubmit}>
              Configure
            </Button>
          </EmptyState>
        </Layout.Section>
      )}
      {!emptyState && (
        <>
          <Layout.AnnotatedSection
            title="Contentful Configuration Details"
            description="The space Id, Management Api Key and Environment Id will be used to establish the connection between the CMS and your store."
          >
            <Card
              sectioned
              actions={[
                {
                  content: 'Edit',
                  disabled: state.config_edit_mode,
                  onAction: () => dispatch({ type: 'toggle-config-edit-mode', payload: { edit_mode: true } }),
                },
              ]}
              primaryFooterAction={{
                content: 'Save',
                onAction: handleSubmit,
                loading: state.savingConfig,
                disabled: !state.config_edit_mode,
              }}
              secondaryFooterActions={[
                {
                  content: 'Cancel',
                  disabled: !state.config_edit_mode,
                  onAction: () => {
                    reset()
                    dispatch({ type: 'toggle-config-edit-mode', payload: { edit_mode: false } })
                  },
                },
              ]}
            >
              {state.config_edit_mode ? (
                <ConfigForm />
              ) : (
                <DescriptionList
                  items={[
                    { term: 'Contentful Space Id', description: values?.space_id },
                    {
                      term: 'Contentful Management API Key',
                      description: `${values?.management_api_key.substring(0, 5)}...`,
                    },
                    { term: 'Contentful Environment', description: values?.environment_id },
                  ]}
                />
              )}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Synchronization Rules"
            description="You set up one or more connections between Products in your store and Content Models in your Contentful Space"
          >
            <Card>
              {rules.length < (config?.max_rules ?? freeQuota.max_rules) && (
                <Card.Header
                  actions={[{ content: 'New rule', url: '/embedded/products-rule' }]}
                  title={'Products Synchronization Rules'}
                />
              )}
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <ProgressBar
                      progress={(sync_count / (config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota)) * 100}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <p>
                      {sync_count} / {config?.monthly_sync_quota ?? freeQuota.monthly_sync_quota}
                    </p>
                  </Stack.Item>
                </Stack>
                <TextStyle variation="subdued">Synchronization limit is applied</TextStyle>

                <RulesList loading={state.rulesLoading} type="products" rules={rules} refetchRules={fetchRules} />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Contentful connections"
            description="You can select a shopify product and look up what contentful entries it is connected to."
          >
            <Card>
              <Card.Header title="Connections" />
              <Card.Section>
                <Button onClick={() => setOpenResourcePicker(true)} primary>
                  Select a product
                </Button>
                <ResourcePicker
                  resourceType="Product"
                  showVariants={false}
                  open={openResourcePicker}
                  onSelection={(resources) => handleSelection(resources)}
                  allowMultiple={false}
                  onCancel={() => setOpenResourcePicker(false)}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <FooterHelp>
            Questions?{' '}
            <Link external url="mailto:office@do.vision">
              Contact us
            </Link>
          </FooterHelp>
        </>
      )}
    </>
  )
}

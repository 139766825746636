import { Layout, Page, Frame, Loading } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect } from 'react'
import { ConfigContextProvider } from '~/containers/ContentfulConfig/ConfigForm'
import { ContentfulConfig } from '~/containers/ContentfulConfig'
import { ServiceContainer } from '~/services'
import { RootStateContext } from '~/components/RootStateProvider'

const Index = () => {
  const { state, dispatch } = useContext(RootStateContext)

  const { config, endpoint, initialized } = state

  const checkCtfConfig = useCallback(async () => {
    return !!config?.space_id && !!config?.environment_id && !!config?.management_api_key
  }, [config])

  const fetchRules = useCallback(async () => {
    if (!endpoint) return
    dispatch({ type: 'loading-rules' })
    const result = await ServiceContainer.client().rules.getAll(endpoint)
    dispatch({ type: 'fetched-rules', payload: { rules: result?.rules ?? [], sync_count: result?.sync_count ?? 0 } })
  }, [dispatch, endpoint])

  const initialize = useCallback(async () => {
    const valid = await checkCtfConfig()
    if (valid) {
      dispatch({ type: 'config-valid-check' })
      await fetchRules()
    }
  }, [checkCtfConfig, dispatch, fetchRules])

  useEffect(() => {
    initialize()
  }, [initialize])

  if (!initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Page>
      <ConfigContextProvider initialValues={config ?? {}}>
        <Layout>
          <ContentfulConfig />
        </Layout>
      </ConfigContextProvider>
    </Page>
  )
}

export default Index

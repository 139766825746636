import React, { createContext, Dispatch, useMemo, useReducer } from 'react'
import { useCallback, useEffect } from 'react'
import { captureException } from '@sentry/nextjs'
import { ServiceContainer } from '~/services'
import { ConfigItem, RuleItem } from '~/services/dynamodb/stores'
import { freeQuota } from '~/data/subscriptionPlans'

type LoadingRules = {
  type: 'loading-rules'
}

type FetchedConfig = {
  type: 'fetched-config'
  payload: { config: ConfigItem | null }
}

type FetchedRules = {
  type: 'fetched-rules'
  payload: { rules: RuleItem[]; sync_count: number }
}

type ConfigValid = {
  type: 'config-valid-check'
}

type SetEndpoint = {
  type: 'set-endpoint'
  payload: { endpoint: string }
}

type UpsertingConfig = {
  type: 'upserting-config'
}

type UpsertedConfig = {
  type: 'upserted-config'
  payload: { config: ConfigItem }
}

type UpsertFailedConfig = {
  type: 'upsert-failed-config'
}

type UpdateConfigState = {
  type: 'updated-config-state'
  payload: { state: EntityState }
}

type RefreshingConfig = {
  type: 'refreshing-config'
}

type ToggleConfigEditMode = {
  type: 'toggle-config-edit-mode'
  payload: { edit_mode: boolean }
}

type UpdatingSubscription = {
  type: 'updating-subscription'
}

type CreatedSubscription = {
  type: 'created-subscription'
}

type FailedUpdateSubscription = {
  type: 'failed-update-subscription'
}

type CheckingDiscountCode = {
  type: 'checking-discount-code'
}

type CheckedDiscountCode = {
  type: 'checked-discount-code'
  payload: { valid: boolean }
}

type CancellingSubscription = {
  type: 'cancelling-subscription'
}

type CancelledSubscription = {
  type: 'cancelled-subscription'
}

type FailedCancelSubscription = {
  type: 'failed-cancel-subscription'
}

export type RootReducerAction =
  | LoadingRules
  | FetchedRules
  | ConfigValid
  | FetchedConfig
  | SetEndpoint
  | UpsertingConfig
  | UpsertedConfig
  | UpdateConfigState
  | UpsertFailedConfig
  | RefreshingConfig
  | ToggleConfigEditMode
  | UpdatingSubscription
  | CreatedSubscription
  | FailedUpdateSubscription
  | CheckingDiscountCode
  | CheckedDiscountCode
  | CancellingSubscription
  | CancelledSubscription
  | FailedCancelSubscription

export enum EntityState {
  IDLE = 'IDLE',
  INVALID = 'INVALID',
  SUCCESS = 'SUCCESS',
}

export interface RootState {
  initialized: boolean
  rulesLoading: boolean
  rulesFetched: boolean
  emptyState: boolean
  endpoint: string | null
  config: ConfigItem
  rules: RuleItem[]
  sync_count: number
  savingConfig: boolean
  loadingConfig: boolean
  config_state: EntityState
  config_edit_mode: boolean
  updatingSubscription: boolean
  checkingDiscountCode: boolean
  validDiscountCode: boolean
  cancellingSubscription: boolean
}

export const rootReducer = (state: RootState, action: RootReducerAction): RootState => {
  switch (action.type) {
    case 'loading-rules':
      return {
        ...state,
        rulesLoading: true,
      }
    case 'fetched-rules':
      return {
        ...state,
        rulesLoading: false,
        rulesFetched: true,
        rules: action.payload.rules,
        sync_count: action.payload.sync_count,
      }
    case 'config-valid-check':
      return {
        ...state,
        emptyState: false,
      }
    case 'refreshing-config':
      return {
        ...state,
        loadingConfig: true,
      }
    case 'fetched-config':
      const config: ConfigItem | null = action.payload.config
      ? {
          ...action.payload.config,
          plan_name: action.payload.config.plan_name ?? freeQuota.name,
          max_rules: action.payload.config.max_rules ?? freeQuota.max_rules,
          monthly_sync_quota: action.payload.config.monthly_sync_quota ?? freeQuota.monthly_sync_quota,
        }
      : null
      return {
        ...state,
        config: config ?? state.config,
        initialized: true,
        loadingConfig: false,
      }
    case 'set-endpoint':
      return {
        ...state,
        endpoint: action.payload.endpoint,
      }
    case 'upserting-config':
      return {
        ...state,
        savingConfig: true,
      }
    case 'upserted-config':
      return {
        ...state,
        savingConfig: false,
        config: action.payload.config,
        emptyState: false,
        config_state: EntityState.SUCCESS,
        config_edit_mode: false,
      }
    case 'updated-config-state':
      return {
        ...state,
        config_state: action.payload.state,
      }
    case 'upsert-failed-config':
      return {
        ...state,
        savingConfig: false,
      }
    case 'toggle-config-edit-mode':
      return {
        ...state,
        config_edit_mode: action.payload.edit_mode,
      }
    case 'updating-subscription':
      return {
        ...state,
        updatingSubscription: true,
      }
    case 'created-subscription':
      return {
        ...state,
        updatingSubscription: false,
      }
    case 'failed-update-subscription':
      return {
        ...state,
        updatingSubscription: false,
      }
    case 'checking-discount-code':
      return {
        ...state,
        checkingDiscountCode: true,
      }
    case 'checked-discount-code':
      return {
        ...state,
        checkingDiscountCode: false,
        validDiscountCode: action.payload.valid,
      }
    case 'cancelling-subscription':
      return {
        ...state,
        cancellingSubscription: true,
      }
    case 'cancelled-subscription':
      const prevConfig = state?.config as ConfigItem
      return {
        ...state,
        cancellingSubscription: false,
        config: {
          ...prevConfig,
          max_rules: freeQuota.max_rules,
          monthly_sync_quota: freeQuota.monthly_sync_quota,
          plan_name: freeQuota.name,
        },
      }
    case 'failed-cancel-subscription':
      return {
        ...state,
        cancellingSubscription: false,
      }
  }
}

export const intialConfig: ConfigItem = {
  endpoint: '',
  data_variant: 'CONFIG',
  space_id: '',
  management_api_key: '',
  environment_id: '',
}

const initialRootState: RootState = {
  initialized: false,
  emptyState: true,
  rulesLoading: false,
  rulesFetched: false,
  endpoint: null,
  config: intialConfig,
  rules: [],
  sync_count: 0,
  savingConfig: false,
  config_state: EntityState.IDLE,
  config_edit_mode: false,
  loadingConfig: false,
  updatingSubscription: false,
  checkingDiscountCode: false,
  validDiscountCode: false,
  cancellingSubscription: false,
}

interface RootStateContextProps {
  state: RootState
  dispatch: Dispatch<RootReducerAction>
}

export const RootStateContext = createContext<RootStateContextProps>({
  state: initialRootState,
  dispatch: () => {},
})

export const RootStateProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(rootReducer, initialRootState)
  const url = new URL(window.location.href)
  let endpoint = url.searchParams.get('shop')

  const getCtfApiKey = useCallback(async () => {
    if (!endpoint) return
    const config = await ServiceContainer.client().config.get(endpoint)
    dispatch({ type: 'fetched-config', payload: { config } })
    if (!config) {
      return
    }
    try {
      const ctfClient = ServiceContainer.ctfManagement(config.management_api_key)
      await ctfClient.initialize(config.space_id, config.environment_id)
    } catch (error) {
      captureException(error)
    }
  }, [endpoint])

  useEffect(() => {
    getCtfApiKey()
  }, [getCtfApiKey])

  const value = useMemo(() => {
    if (!state.endpoint && endpoint) {
      dispatch({ type: 'set-endpoint', payload: { endpoint } })
    }
    return {
      state,
      dispatch,
    }
  }, [endpoint, state])
  return <RootStateContext.Provider value={value}>{props.children}</RootStateContext.Provider>
}

import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Card, EmptySearchResult, Frame, Layout, Loading, Page, ResourceItem, ResourceList } from '@shopify/polaris'
import { Entry } from 'contentful-management'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from '~/components/Link'
import { RootStateContext } from '~/components/RootStateProvider'
import { ServiceContainer } from '~/services'

export const ProductCtfEntriesList = () => {
  const router = useRouter()
  const { state } = useContext(RootStateContext)
  const shopifyProductId = router.query?.id

  const app = useAppBridge()

  const [loading, setLoading] = useState<boolean>(false)
  const [fetched, setFetched] = useState<boolean>(false)

  const [ctfEntries, setCtfEntries] = useState<Entry[]>([])

  const redirectToContentful = useCallback(
    async (entryId: string) => {
      const space = ServiceContainer.ctfManagement(state.config.management_api_key).space
      const redirect = Redirect.create(app)
      redirect.dispatch(Redirect.Action.REMOTE, {
        url: `https://app.contentful.com/spaces/${space?.sys?.id ?? ''}/entries/${entryId}`,
        newContext: true,
      })
    },
    [app, state.config.management_api_key],
  )

  const fetchCtfEntries = useCallback(async () => {
    if (!state?.endpoint || !shopifyProductId || loading || fetched) return
    setLoading(true)
    const {ctfEntryIds} = await ServiceContainer.client().ctf_entries.ofProduct(state.endpoint, shopifyProductId as string)
    if (ctfEntryIds.length === 0) {
      setCtfEntries([])
      setLoading(false)
      setFetched(true)
      return
    }

    if (ctfEntryIds.length === 1) {
      redirectToContentful(ctfEntryIds[0])
      const ctfEntry = await ServiceContainer.ctfManagement().getEntry(ctfEntryIds[0])
      setCtfEntries([ctfEntry])
      setLoading(false)
      setFetched(true)
      return
    }

    const contentfulEntries: Entry[] = await Promise.all(
      ctfEntryIds.map(async (id: string) => {
        const entry = await ServiceContainer.ctfManagement(state.config.management_api_key).getEntry(id)
        return entry
      }),
    )
    setCtfEntries(contentfulEntries)
    setLoading(false)
    setFetched(true)
    return

  }, [state.endpoint, state.config.management_api_key, shopifyProductId, loading, fetched, redirectToContentful])

  useEffect(() => {
    fetchCtfEntries()
  }, [fetchCtfEntries, shopifyProductId])

  const renderItem = (item: Entry, id: string, idx: number) => {
    return (
      <ResourceItem
        id={item.sys.id}
        onClick={() => {}}
        accessibilityLabel={`Navigate to contentful entry with id ${item.sys.id}`}
      >
        <h3>
          <strong>
            {`${item.sys.contentType.sys.id} entry ${idx + 1}`}{' '}
            <Link url={`https://app.contentful.com/spaces/${item.sys.space.sys.id}/entries/${item.sys.id}`} external>
              Go to entry
            </Link>
          </strong>
        </h3>
      </ResourceItem>
    )
  }

  const emptyStateMarkup =
    fetched && ctfEntries.length === 0 ? <EmptySearchResult title={'No entries found'} withIllustration /> : undefined

  if (!state.initialized)
    return (
      <div style={{ height: '100px' }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )

  return (
    <Page
      title="Entries"
      pagination={{ hasPrevious: true, hasNext: false, onPrevious: () => router.back() }}
      divider={true}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              items={ctfEntries}
              renderItem={renderItem}
              selectable={false}
              loading={loading}
              emptyState={emptyStateMarkup}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default ProductCtfEntriesList
